const resolvedOptions = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  currencyDisplay: "code",
}).resolvedOptions();

export const fiatFormat = function (amountInCents: number): string {
  const amount = amountInCents / 100;
  const amountStr = amount.toLocaleString("pt-BR", resolvedOptions);

  return amountStr.substring(4);
};

export const formatBRL = (value: number): string => {
  const brl = value / 100;
  const brlStr = brl.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return brlStr.replace("R$", "");
};

export const parseBRL = (value: string): number => {
  value = value.replace("R$ ", "");
  // @ts-ignore
  value = value.replaceAll(".", "");
  value = value.replace(",", "");

  if (value.length == 0) {
    return 0;
  }

  const finalValue = parseInt(value);

  if (Number.isNaN(finalValue)) {
    return 0;
  }

  return finalValue;
};
