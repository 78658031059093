import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

interface InputStyleProps {
  error?: boolean;
  filled?: boolean;
  textarea?: boolean;
}

export const Parent = styled.div<InputStyleProps>`
  font-family: Montserrat, sans-serif;
  margin-bottom: 9px;
  border-radius: 2px;
  height: 56px;
  position: relative;

  input:focus + label,
  textarea:focus + label {
    font-weight: 600;
    color: ${theme('colors.primary.main')};
    font-size: 12px;
    line-height: 15px;
    top: 16px;
  }  

  ${({ textarea }) =>
    textarea &&
    css`
      height: 125px;
  `}
`;

export const Input = styled.input<InputStyleProps>`
  font-family: Montserrat, sans-serif;  
  color: ${theme('colors.white')};
  background-color: ${theme('colors.dark.gray.1')};
  width: 100%;
  border: 1px solid ${theme('colors.dark.gray.2')};
  height: 100%;
  padding: 25px 15px 9px 15px;
  border-radius: 2px;
  transition: border-color 0.25s ease;
  font-size: 16px;

  ${({ filled }) =>
    filled &&
    css`
      & + label {
      font-weight: 600;
      color: ${theme('colors.dark.gray.3')};
      font-size: 12px;
      line-height: 15px;
      top: 16px;
   }
  `}

  &:focus {
    border: 1px solid ${theme('colors.primary.main')};
    outline: none;
  }
`;

export const Textarea = styled.textarea<InputStyleProps>`
  font-family: Montserrat, sans-serif;  
  color: ${theme('colors.white')};
  background-color: ${theme('colors.dark.gray.1')};
  width: 100%;
  border: 1px solid ${theme('colors.dark.gray.2')};
  height: 100%;
  padding: 25px 15px 9px 15px;
  border-radius: 2px;
  transition: border-color 0.25s ease;
  font-size: 16px;
  height: 125px;

  ${({ filled }) =>
    filled &&
    css`
      & + label {
      font-weight: 600;
      color: ${theme('colors.dark.gray.3')};
      font-size: 12px;
      line-height: 15px;
      top: 16px;
   }
  `}

  &:focus {
    border: 1px solid ${theme('colors.primary.main')};
    outline: none;
  }
`;

export const Placeholder = styled.label`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: ${theme('colors.dark.gray.3')};
  position: absolute;
  left: 15px;
  top: 16px;
  transform: translateY(-50%);

  transition: all 0.25s ease;
`;
