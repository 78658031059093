import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { theme } from 'styled-tools';

interface InputStyleProps {
  error?: boolean;
  filled?: boolean;
}

const labelFloat = css`
  color: ${theme('colors.secondary.3')};
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
  top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 30px);
`;

export const Parent = styled.div<SpaceProps & InputStyleProps>`
  position: relative;
  ${space}

  input[type="email"] {
    text-transform: lowercase;
  }

  input {
    border: 1px solid ${({ error }) => theme(error ? 'colors.error.main' : 'colors.secondary.4')};
    background-color: ${theme('colors.white')};
    color: ${theme('colors.secondary.1')};
    transition: all 0.25s ease;
    padding: 12px 15px 0;
    border-radius: 2px;
    line-height: 22px;
    font-size: 16px;
    height: 56px;
    width: 100%;

    &:disabled {
      background-color: ${theme('colors.white')};
      color: ${theme('colors.secondary.3')};

      & + label {
        color: ${theme('colors.secondary.3')};
        cursor: default;
      }
    }

    &:focus {
      border-color: ${({ error }) => theme(error ? 'colors.error.main' : 'colors.primary.main')};
      outline: none;

      & + label {
        color: ${({ error }) => theme(error ? 'colors.error.main' : 'colors.primary.main')} !important;
        ${labelFloat}
      }
    }
  }
`;

export const Input = styled.input<InputStyleProps>`
  width: 100%;
  height: 56px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 2px;
  padding: 12px 15px 0;
  transition: all 0.25s ease;
  color: ${theme('colors.secondary.1')};
  background-color: ${theme('colors.white')};
  border: 1px solid ${({ error }) => theme(error ? 'colors.error.main' : 'colors.secondary.4')};

  &:focus {
    border-color: ${({ error }) => theme(error ? 'colors.error.main' : 'colors.primary.main')};
    outline: none;

    & + label {
      color: ${({ error }) => theme(error ? 'colors.error.main' : 'colors.primary.main')} !important;
      ${labelFloat}
    }
  }

  &:disabled {
    background-color: ${theme('colors.white')};
    color: ${theme('colors.secondary.3')};

    & + label {
      color: ${theme('colors.secondary.3')};
      cursor: default;
    }
  }
`;

export const Placeholder = styled.label<InputStyleProps>`
  color: ${theme('colors.secondary.2')};
  cursor: text;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  left: 18px;
  position: absolute;
  top: 20px;
  transition: all 0.25s ease;

  &.label-height {
    top: 15px;
  }

  ${({ error }) =>
    error &&
    css`
      color: ${theme('colors.error.main')} !important;
      ${labelFloat}
    `}

  ${({ filled }) =>
    filled &&
    css`
      ${labelFloat}
    `}
`;

export const ErrorMessage = styled.span`
  color: ${theme('colors.error.main')};
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  transform: translateY(100%);
`;
