import styled from "styled-components";

interface Props {
  full?: boolean;
  soon?: boolean;
}

export const Container = styled.div<Props>`
  width: ${(props) => (props.full ? "100%" : "404px")};
  border: 1px solid #324455;
  border-radius: 2px;
  padding: 32px 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 22px 29px;
  }

  button {
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
    padding: 0;
    border: none;
    text-decoration: underline;
    transition: color .25s;

    :hover {
      color: #FFBD88;
    }

    :disabled {
      color: #5a6473;
      pointer-events: none;
    }

    :hover {
      text-decoration-line: underline;
    }
  }
`;



export const Infos = styled.div<Props>`
  span {
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => (props.soon ? "#8C96A0" : "#fafafa")};
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${(props) => (props.soon ? "#5A6473" : "#8c96a0")};
    margin: 6px 0 23px 0;
    
    @media only screen and (max-width: 1200px) {
      width: 100%;
      margin-bottom: 0;
    }
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #1e2c39;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    color: #fc9c44;
    padding: 5px 15px;
    background-color: #1e2c39;
    border-radius: 2px;
  }
`;
