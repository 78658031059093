import React from "react";

export type IconName =
  | "2nd"
  | "arrow"
  | "arrow-alternative"
  | "bag"
  | "bank"
  | "bitcoin"
  | "bitcoin-cash"
  | "bkai-coin"
  | "block"
  | "boleto"
  | "calendar"
  | "card"
  | "cardano"
  | "change"
  | "check"
  | "clock"
  | "close"
  | "coin"
  | "copy"
  | "disable"
  | "download"
  | "edit"
  | "ethereum"
  | "expand"
  | "expanded"
  | "external-link"
  | "fixed"
  | "github"
  | "graphic"
  | "hide-bar"
  | "hide"
  | "home"
  | "image"
  | "info"
  | "key"
  | "linkedin"
  | "list"
  | "logout"
  | "mail"
  | "menu"
  | "message"
  | "more"
  | "notification"
  | "ordenation"
  | "origin"
  | "phone"
  | "pix"
  | "qrcode"
  | "receive"
  | "scan"
  | "search"
  | "send"
  | "settings"
  | "share"
  | "show-bar"
  | "store"
  | "tether"
  | "time"
  | "token"
  | "trash"
  | "unblock"
  | "upload"
  | "user"
  | "validation"
  | "variation-down"
  | "variation-up"
  | "view"
  | "wallet"
  | "work"
  | "world";

export type IconProps = {
  name: IconName;
  color?: string;
  top?: number;
  size?: number;
  title?: string;
  rotate?: string;
};

const Icon: React.FC<IconProps> = ({
  name,
  color,
  top,
  size,
  title,
  rotate,
}) => {
  let transform = rotate ? `rotate(${rotate})` : "";

  return (
    <i
      className={`icon-${name}`}
      style={{
        display: "inline-flex",
        position: "relative",
        alignItems: "center",
        fontSize: size,
        color: color,
        transform: transform,
        transition: "color 0.25s",
      }}
      title={title}
    />
  );
};

export default Icon;
