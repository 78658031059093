import styled from "styled-components";
import { theme } from "styled-tools";
import Icon from "../Icon";

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 25%;
    bottom: 30px;
    width: 4px;
    height: 4px;
    background-color: ${theme("colors.primary.main")};
  }

  .decoration {
    position: absolute;
    left: 644px;
    top: 120px;
    width: 9px;
    height: auto;
  }

  .decorationDot {
    position: absolute;
    left: 419px;
    top: 529px;
    width: 9px;
    height: auto;
  }

  @media only screen and (max-width: 1025px) {
    flex-direction: column;

    .decoration {
      display: none;
    }

    .decorationDot {
      display: none;
    }
  }
`;

export const BoxLeft = styled.article`
  width: 34vw;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 29px;
    font-weight: 500;
    font-size: 14px;
    color: #fafafa;
    transition: 0.5s;
    background-color: transparent;
    border: none;
    padding: 0;

    i {
      line-height: 100%;
    }

    :hover {
      color: #ff465a;
      transition: 0.5s;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
    margin: 8px 0 16px 0;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;

    b {
      color: #fafafa;
      font-weight: 500;
    }
  }

  button {
    margin-top: 28px;
    position: relative;
  }

  @media only screen and (max-width: 1025px) {
    display: flex;
    width: 100%;
    flex-direction: column;

    a {
      margin-top: 39.5px;
      align-self: center;
      font-weight: 500;
      font-size: 14px;
      color: #fafafa;
      transition: 0.5s;
      background-color: transparent;
      border: none;
      padding: 0;

      :hover {
        color: #ff465a;
        transition: 0.5s;
      }
    }

    h1 {
      font-size: 30px;
      line-height: 36px;
    }

    button {
      width: 100%;
      margin-top: 20px;
    }
  }
`;

export const BoxRight = styled.div`
  position: relative;

  @media only screen and (max-width: 1025px) {
    width: 100%;
    margin-top: 60px;

    .img-block {
      width: 100%;
    }
  }
`;

export const Button = styled.button`
  background-color: ${theme("colors.primary.main")};
  width: 174px;
  height: 46px;
  border: none;
  box-shadow: 0px 10px 25px rgba(252, 156, 68, 0.12);
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  color: ${theme("colors.white")};
  transition: 0.25s;

  &:hover {
    background-color: ${theme("colors.primary.dark")};
  }

  &:disabled {
    background-color: #1e2c39;
    cursor: default;
    color: #5a6473;
  }

  &.alternative {
    background-color: #1e2c39;
    box-shadow: none;
    color: #fc9c44;

    span {
      color: #fc9c44 !important;
    }

    :hover {
      background-color: ${theme("colors.primary.dark")};
      transition: 0.5s;
      color: #fafafa;

      span {
        color: #fafafa !important;
      }
    }
  }
`;
