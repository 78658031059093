import "../styles/global.css";
import { themes } from "@2ndmarket/components";

export default function App({ Component, pageProps }) {
  const { ThemeProvider, bankai } = themes;

  return (
    <ThemeProvider theme={bankai}>
      <Component {...pageProps} />
    </ThemeProvider>
  );
}
