import styled from "styled-components";
import { theme } from 'styled-tools';

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 85px 0;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 45px 0;
  }

  &::before {
    content: '';
    background-color: ${theme('colors.primary.main')};
    width: 3px;
    height: 3px;
    position: absolute;
    left: 30vw;
    bottom: 40px;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
`;

export const BoxText = styled.div`
  max-width: 34vw;

  @media only screen and (max-width: 1200px) {
    max-width: 100%;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const MailLink = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  i {
    color: ${theme('colors.primary.main')};
    padding-top: 2px;

    &::before {
      display: block;
    }
  }

  p {
    margin-left: 10px;
    color: ${theme('colors.dark.gray.4')};
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const BoxForm = styled.form`
  min-width: 38vw;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin-top: 50px;
  }  
`;

export const BoxSelect = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme('colors.white')};
    background-color: ${theme('colors.primary.main')};
    width: 172px;
    margin-top: 30px;
    height: 46px;
    width: 202px;
    border-radius: 2px;
    transition: background-color .25s;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      margin-top: 20px;
    }

    &:hover {
      background-color: ${theme('colors.primary.dark')};
    }
  }
`