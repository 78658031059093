export default {
	name: "bankai",
	breakpoints: {
		extralarge: "1200px", // >= 1200px
		large: "992px", // >= 992px
		medium: "768px", // >= 768px
		small: "576px", // >= 576px
		extrasmall: "375px", // < 576px
	},
	fontFamily: ["Montserrat, Arial, Helvetica, sans-serif"],
	space: [
		0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75,
		80, 85, 90, 95, 100,
	],
	colors: {
		primary: {
			main: "#E71346",
			dark: "#B10726",
			light: "#F4A3AC",
		},
		secondary: {
			1: "#5A6473",
			2: "#8C96A0",
			3: "#BBC2CB",
			4: "#EFF1F6 ",
			5: "#7E3FF2",
		},
		light: {
			gray: {
				1: "#292D37",
				2: "#535668",
				3: "#B4B9C7",
				4: "#E5E9EE",
			},
			background: "#FBFBFF",
		},
		dark: {
			gray: {
				1: "#1E2C39",
				2: "#324455",
				3: "#5A6473",
				4: "#C6CFDB",
			},
		},
		white: "#ffffff",
		black: "#0F1E2D",
		success: {
			main: "#4BD281",
			bg: "#E9F7F3",
		},
		error: {
			main: "#DC3545",
			bg: "#F8ECEE",
		},
		alert: {
			main: "#F7931A",
			bg: "#F9F2EA",
		},
		crypto: {
			bitcoin: "#FF8724",
			bkaicoin: "#E71346",
			ethereum: "#2992FA",
			cardano: "#3361D1",
			tether: "#1BA27A",
			ripple: "#2992FA",
		},
		bgInput: "#ffffff",
		background: "#FDFDFF",
		color: "#324455",
		blur: "rgba(255, 255, 255, 0.2)",
		modalBorder: "white",
		status: {
			active: "#4150E6",
			nokyc: "#DC3545",
			analising: "#F7AC1A",
			locked: "#979CC8",
			deactivated: "#C9D1DA",
		},
	},
};
