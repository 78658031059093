import styled from "styled-components";
import { theme } from 'styled-tools';

interface Props {
  center?: boolean;
}

export const Container = styled.div`
  margin: 85px 0 85px 0;

  img {
    position: absolute;

    &.decoration1 {
      top: 43.62%;
      right: 12.81%;

      @media only screen and (max-width: 1025px) {
        display: none;
      }
    }

    &.decoration2 {
      top: 42.2%;
      right: 10.56%;

      @media only screen and (max-width: 1025px) {
        display: none;
      }
    }

    &.decoration3 {
      top: 46.36%;
      right: 31.88%;

      @media only screen and (max-width: 1025px) {
        right: 73.47%;
        top: 47.3%;
      }
    }

    &.decoration4 {
      width: 236px;
      top: 56.27%;
      left: -7.57%;

      @media only screen and (max-width: 1025px) {
        width: 117px;
        top: 46.5%;
        left: 60.53%;
      }
    }
  }

  @media only screen and (max-width: 1025px) {
    margin: 70px 0 60px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 28px;


  @media only screen and (max-width: 1025px) {
    gap: 9px;

    .button {
      display: none;
    }

    a   
     {
      display: none;
    }
  }
`;

export const Line = styled.div<Props>`
  display: flex;
  gap: 28px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 9px;
  }
`;

export const Title = styled.div`
  max-width: 48vw;
  margin-bottom: 48.6px;

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 21.94px;
    color: #FF465A;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21.94px;
    color: #fafafa;
  }

  @media only screen and (max-width: 1025px) {
    max-width: 100%;
    margin-bottom: 30px;

    h1 {
      font-weight: 600;
      font-size: 18px;
      line-height: 21.94px;
      color: #fafafa;
    }
    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 21.94px;
      color: #FF465A;
    }
  }
`;

export const Wrapbuttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 60px;
  
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 29px;
    font-weight: 500;
    font-size: 14px;
    color: #fafafa;
    transition: 0.5s;
    background-color: transparent;
    border: none;
    padding: 0;

    :hover {
      color: ${theme('colors.primary.main')};
      transition: 0.5s;
    }
  }

  @media only screen and (max-width: 1025px) {
    display: none;
  }
`

export const Button = styled.button`
  background-color: ${theme('colors.primary.main')};
  width: 174px;
  height: 46px;
  border: none;
  box-shadow: 0px 10px 25px rgba(252, 156, 68, 0.12);
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  color: ${theme('colors.white')};
  transition: 0.25s;
  
  &:hover {
    background-color: ${theme('colors.primary.dark')};
  }

  &:disabled {
    background-color: #1e2c39;
    cursor: default;
    color: #5a6473;
  }

  &.alternative {
    background-color: #1e2c39;
    box-shadow: none;
    color: #fc9c44;

    span {
      color: #fc9c44 !important;
    }

    :hover {
      background-color: ${theme('colors.primary.dark')};
      transition: 0.5s;
      color: #fafafa;

      span {
        color: #fafafa !important;
      }
    }
  }
`;