import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
	display: flex;
	padding: 85px 6vw 30px 6vw;
	position: relative;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		padding: 45px 0 56px 0;
	}
`;

export const Left = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding-bottom: 40px;
	}
`;

export const ImageAbout = styled.img`
	width: 100%;
	height: 100%;
`;

export const Right = styled.div`
	width: 50%;
	display: flex;
	padding-left: 5vw;
	flex-direction: column;

	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding-left: 0;
	}
`;

export const ImageDot = styled.img`
	top: 50px;
	width: 4px;
	height: 4px;
	right: 250px;
	position: absolute;

	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;

export const H4 = styled.h4`
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	font-family: ${theme("fontFamily.0")};
	color: ${theme("colors.primary.main")};
`;

export const H2 = styled.h2`
	font-size: 32px;
	font-weight: 600;
	line-height: 36px;
	font-family: ${theme("fontFamily.0")};
	color: ${theme("colors.white")};
`;

export const P = styled.p`
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	margin-top: 20px;
	margin-bottom: 20px;
	font-family: ${theme("fontFamily.0")};
	color: ${theme("colors.secondary.2")};
`;

export const ButtonLink = styled.a`
	width: 202px;
	height: 46px;
	border-radius: 2px;

	font-size: 14px;
	font-weight: 500;
	color: ${theme("colors.white")};
	font-family: ${theme("fontFamily.0")};

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	background-color: ${theme("colors.primary.main")};
  transition: 0.25s;
  
  &:hover {
    background-color: ${theme('colors.primary.dark')};
  }

	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding: 20px 0;
	}
`;
