import Head from "next/head";
import { useRouter } from "next/router";

interface SocialTag {
  name: string;
  content: string;
}

interface Props {
  appName: string;
  title: string;
  description: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  themeColor?: string;
  url?: string;
  socialTags?: SocialTag[];
}

const SEO: React.FC<Props> = ({
  title,
  appName,
  description,
  image,
  imageWidth,
  imageHeight,
  themeColor,
  url,
  socialTags,
}) => {
  const { pathname } = useRouter();

  return (
    <Head>
      <title>
        {title} | {appName}
      </title>
      <meta name="og:title" content={title + " | " + appName} />
      <meta property="og:site_name" content={appName} />
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}

      {themeColor && <meta name="theme-color" content={themeColor} />}

      <link rel="icon" href="/favicon.ico" />
      <meta name="language" content="PT" />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />

      {image && <meta property="og:image" content={image} />}
      {imageWidth && <meta property="og:image:width" content={imageWidth} />}
      {imageHeight && <meta property="og:image:height" content={imageHeight} />}
      {url && <meta property="og:url" content={url + pathname} />}

      {socialTags?.map(({ name, content }, index) => {
        return <meta key={index} name={name} content={content} />;
      })}
    </Head>
  );
};

export default SEO;
