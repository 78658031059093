import React, { useState } from "react";
import TextField from "../TextField";
import { TextFieldProps } from "../TextField/TextField";
import textToNumber from "../helper/textToNumber";
import { BTC_DECIMAL_PLACES } from "../helper/btcProvider";

const TextFieldCrypto: React.FC<TextFieldProps> = React.forwardRef(
  (
    {
      name,
      value = "",
      placeholder,
      error,
      disabled,
      mb,
      mt,
      readOnly = false,
      onChange = () => {},
      invalidChars,
      className,
    },
    ref
  ) => {
    const [text, setText] = useState(value);

    const onTextChange = (newText: string) => {
      const trimmedText = newText.trim().replaceAll(".", ",");

      if (trimmedText == ",") {
        onChange("0");
        setText("0,");
        return;
      }

      const amountInBtc = textToNumber(trimmedText, BTC_DECIMAL_PLACES);

      onChange(amountInBtc.toString());
      setText(trimmedText);
    };

    return (
      <TextField
        name={name}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        mb={mb}
        mt={mt}
        invalidChars={invalidChars}
        readOnly={readOnly}
        value={text}
        onChange={onTextChange}
        className={className}
        autocomplete="off"
      />
    );
  }
);

TextFieldCrypto.displayName = "TextFieldCrypto";

export default TextFieldCrypto;
