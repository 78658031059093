import styled from "styled-components";
import { theme } from "styled-tools";

export const Title = styled.h2`
  color: ${(props: any) =>
    props.color ? props.color : theme("colors.primary.main")};
  font-weight: ${(props: any) => (props.weight ? props.weight : "500")};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
`;

export const Subtitle = styled.h1`
  font-size: ${(props: any) => (props.size ? props.size : "42px")};
  font-weight: ${(props: any) => (props.weight ? props.weight : "700")};
  line-height: ${(props: any) => (props.height ? props.height : "50px")};
  color: ${(props: any) => (props.color ? props.color : theme("colors.white"))};
  margin: 0;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: ${(props: any) => (props.color ? props.color : "#8C96A0")};
  margin: 0;
`;

export const Additional = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props: any) => (props.color ? props.color : "#5a6473")};
  margin: 30px 0;
`;

export const Strong = styled.strong`
  font-weight: 600;
`;
