export default {
  name: '2nd',
  nextjs: true,
  breakpoints: {
    mobile: '1200px',
    mobileBackoffice: '1149px',
    desktopBackoffice: '1150px',
  },
  fontFamily: ['Montserrat, Arial, Helvetica, sans-serif'],
  space: [
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75,
    80, 85, 90, 95, 100,
  ],
  colors: {
    primary: {
      main: '#4150E6',
      dark: '#1A2489',
      light: '#BCBCF4',
    },
    secondary: {
      1: '#5A6473',
      2: '#8C96A0',
      3: '#C9D1DA',
      4: '#EFF1F6 ',
    },
    white: '#ffffff',
    black: '#000000',
    success: {
      main: '#4BD281',
      bg: '#E9F7F3',
    },
    error: {
      main: '#DC3545',
      bg: '#F8ECEE',
    },
    alert: {
      main: '#F7931A',
      bg: '#F9F2EA',
    },
    bgInput: '#ffffff',
    background: '#FBFBFF',
    color: '#324455',
    blur: 'rgba(255, 255, 255, 0.2)',
    modalBorder: 'white',
    status: {
      active: '#4150E6',
      nokyc: '#DC3545',
      analising: '#F7AC1A',
      locked: '#979CC8',
      deactivated: '#C9D1DA',
    },
  },
};
