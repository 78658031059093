import { ThemeProvider as ThemeProviderStyled } from 'styled-components';
import { default2nd } from '.';
import GlobalStyle from '../styles';

type ThemeProviderProps = {
  children: any;
  theme?: object;
};

const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  theme = default2nd,
}) => {
  return (
    <ThemeProviderStyled theme={theme}>
      <link
        href="https://2nd-cdn-bucket.s3.amazonaws.com/2nd-market-icons/v7/styles.css"
        rel="stylesheet"
      />

      {children}

      <GlobalStyle />
    </ThemeProviderStyled>
  );
};

export default ThemeProvider;
